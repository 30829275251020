export const glyphs = [
  '\u2045',
  '\u2046',
  '\u00a6',
  '\u002D',
  '\u2013',
  '\u2012',
  '\u2014',
  '\u2015',
  '\u2022',
  '\u25e6',
  '\u2023',
  '\u204c',
  '\u204d',
  '\u00b7',
  '\u2025',
  '\u2026',
  '\u2039',
  '\u203a',
  '\u00ab',
  '\u00bb',
  '\u2264',
  '\u2265',
  '\u2260',
  '\u002B',
  '\u2212',
  '\u00d7',
  '\u00f7',
  '\u00b1',
  '\u2248',
  '\u007E',
  '\u00ac',
  '\u2020',
  '\u2021',
  '\u005E',
  '\u00ae',
  '\u00a9',
  '\u2117',
  '\u2122',
  '\u2120',
  '\u2121',
  '\u213b',
  '\ud83c\udd6b',
  '\ud83c\udd6a',
  '\u00b0',
  '\u00b6',
  '\u204b',
  '\u00a7',
  '\u221e',
  '\u2202',
  '\u2211',
  '\u220f',
  '\u222b',
  '\u221a',
  '\u2205',
  '\u25ca',
  '\u00bd',
  '\u2153',
  '\u00bc',
  '\u215b',
  '\u215f',
  '\u00be',
  '\u215c',
  '\u215a',
  '\u215d',
  '\u215e',
  '\u0025',
  '\u2030',
  '\u2031',
  '\u214d',
  '\u2106',
  '\u2105',
  '\u2100',
  '\u2101',
  '\u00a8',
  '\u02c6',
  '\u02dc',
  '\u00af',
  '\u02d8',
  '\u02d9',
  '\u02da',
  '\u02c7',
  '\u0385',
  '\u00b8',
  '\u02db',
  '\u2116',
  '\u2103',
  '\u2109',
  '\u00c5',
  '\u0298',
  '\u212e',
  '\u2190',
  '\u2192',
  '\u27f5',
  '\u27f6',
  '\u21d0',
  '\u21d2',
  '\u27f8',
  '\u27f9',
  '\u2196',
  '\u2197',
  '\u2199',
  '\u2198',
  '\u2191',
  '\u2193',
  '\u2195',
  '\u2194',
  '\u27f7',
  '\u21d4',
  '\u27fa',
  '\u21b0',
  '\u21b1',
  '\u21b5',
  '\u21b3',
  '\u21b4',
  '\u23ce',
  '\u21e4',
  '\u21e5',
  '\u21de',
  '\u21df',
  '\u21ba',
  '\u21bb',
  '\u238b',
  '\u21a9',
  '\u21aa',
  '\u2713',
  '\u2717',
  '\u25b2',
  '\u25bc',
  '\u25c4',
  '\u25b6',
  '\u25b3',
  '\u25bd',
  '\u25c5',
  '\u25bb',
  '\u26a0',
  '\u25cf',
  '\u25cb',
  '\u25a0',
  '\u25a1',
  '\u25a2',
  '\u2b12',
  '\u2b13',
  '\u25c6',
  '\u25c7',
  '\u2756',
  '\u2600',
  '\u263c',
  '\u2665',
  '\u2661',
  '\u2764',
  '\u2605',
  '\u2606',
  '\u2b06',
  '\u21e7',
  '\u21ea',
  '\u2318',
  '\u2303',
  '\u2305',
  '\u2325',
  '\u2387',
  '\u232b',
  '\u2326',
  '\u2327',
  '\u23cf',
  '\u25ef',
  '\u2b1c',
  '\u24b6',
  '\u24b7',
  '\u24b8',
  '\u24b9',
  '\u24ba',
  '\u24bb',
  '\u24bc',
  '\u24bd',
  '\u24be',
  '\u24bf',
  '\u24c0',
  '\u24c1',
  '\u24c2',
  '\u24c3',
  '\u24c4',
  '\u24c5',
  '\u24c6',
  '\u24c7',
  '\u24c8',
  '\u24c9',
  '\u24ca',
  '\u24cb',
  '\u24cc',
  '\u24cd',
  '\u24ce',
  '\u24cf',
  '\u24ea',
  '\u2780',
  '\u2781',
  '\u2782',
  '\u2783',
  '\u2784',
  '\u2785',
  '\u2786',
  '\u2787',
  '\u2788',
  '\ud83c\udd30',
  '\ud83c\udd31',
  '\ud83c\udd32',
  '\ud83c\udd33',
  '\ud83c\udd34',
  '\ud83c\udd35',
  '\ud83c\udd36',
  '\ud83c\udd37',
  '\ud83c\udd38',
  '\ud83c\udd39',
  '\ud83c\udd3a',
  '\ud83c\udd3b',
  '\ud83c\udd3c',
  '\ud83c\udd3d',
  '\ud83c\udd3e',
  '\ud83c\udd3f',
  '\ud83c\udd40',
  '\ud83c\udd41',
  '\ud83c\udd42',
  '\ud83c\udd43',
  '\ud83c\udd44',
  '\ud83c\udd45',
  '\ud83c\udd46',
  '\ud83c\udd47',
  '\ud83c\udd48',
  '\ud83c\udd49',
  '\u00a4',
  '\u20a3',
  '\u20be',
  '\u20a8',
  '\u20bc',
  '\u20aa',
  '\u20a5',
  '\u20af',
  '\u20ae',
  '\u20a2',
  '\u20b5',
  '\u20a1',
  '\u20b2',
  '\u20a6',
  '\u20b4',
  '\u20b3',
  '\u20a4',
  '\u20a9',
  '\u20ad',
  '\u20b1',
  '\u20a7',
  '\u20b8',
  '\u20b9',
  '\u20ac',
  '\u20bd',
  '\u20ba',
  '\u0192',
  '\u00a3',
  '\u00a5',
  '\u00a2',
  '\u0024'
];
